// MOVE TO HUI
.atlas-theme-intro {
  background: rgb(192, 192, 206);
  background: linear-gradient(347deg, rgba(192, 192, 206, 1) 50%, rgba(226, 226, 234, 1) 100%);
  .hero-shot.appName {
    color: #000 !important;
  }
  .logo {
    .badge {
      margin-left: 8px;
    } // not the proper solution
  }
  .rightbar {
    min-height: 100vh;

    color: #000;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1), 0px 0px 16px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
}

.crop-description {
  padding: 1rem 2rem;
}
.list-group-item:focus .primary {
  font-weight: 600 !important;
}
.form-container {
  min-height: 50vh;
  width: 450px;
  margin-top: 9rem; //desktop only
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  div:first-child {
    flex-grow: 2;
  }
  .form-control {
    font-size: 1.25rem;
    text-align: center;
  }
}
.modal-header .close {
  margin: -1.5rem -1rem -1rem auto;
}
.paging-container {
  // position: absolute;
  margin-bottom: 3rem;
  // width: 500px;
  //left: 50%;
  //transform: translate(-50%, -50%);
  // margin-left: -250px;
}

.button-continue {
  //position: absolute;
  //bottom: 5rem;
  //left: 50%;
  //transform: translate(-50%, -50%);
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.red-underline {
  border-bottom: 1px dashed #ed1c42;
  font-weight: bold;
}
.welcome-steps {
  div {
    span {
      color: #000;
      font-size: 2.5rem;
      font-weight: bold;
      position: absolute;
      display: inline-block;
      line-height: 1px;
      width: 3rem;
      margin-top: -0.8rem;
      flex-grow: 0;
    }
    p {
      margin-left: 2rem;
      line-height: 1.2 !important;
    }
    text-align: left !important;
    height: 340px;
    display: flex;
    font-weight: 200;
    width: 50%;
    text-align: center;
    padding: 1rem;
    overflow: hidden;
    color: #000;
  }
  div:nth-child(1) {
    background-color: #52c9ff;
    background-repeat: no-repeat;
    background-image: url('/password-2.png');
    background-size: 240%;
    background-position: 50% -5%;
    line-height: 1.1 !important;
  }
  div:nth-child(3) {
    background-repeat: no-repeat;
    background-color: #f3fba0;
    width: 50%;
    background-image: url('/duo-step-2.png');
    background-size: 210%;
    background-position: 68% -35%;
    line-height: 1.1 !important;
  }
  div:nth-child(2) {
    background-color: #dd9cfa;
    width: 50%;
    background-image: url('/upload-photo-2.png');
    background-size: 250%;
    background-position: 50% -10%;
    background-repeat: no-repeat;
  }
}

.modal.containerless .modal-body {
  overflow-y: scroll !important;
}
.Backdrop {
  backdrop-filter: blur(10px) !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  filter: blur(0px) !important;
}
.modal-bottom {
  margin-bottom: -5rem;
  padding-bottom: 8rem;
}
.intro-text {
  margin: 0rem 6.5rem; //desktop only
  color: #000;

  h1 {
    color: #ed1c42;
    margin-bottom: 2rem;
    line-height: 1.1 !important;
  }
  h2 {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
  h3 {
    font-weight: 100;
    font-size: 1rem !important;
  }
}

.footer-bar {
  color: #000;
  position: fixed;
  z-index: 5;
  bottom: 2rem;
  left: 2rem;
  a {
    color: #000;
    &:hover {
      color: #ed1c42;
      transition-duration: 0.05s;
    }
  }
}

.btn-big-icon i:active {
  outline: none;
}

.appstore-image {
  max-height: 50px;
}

.duo-qr img {
  height: 150px;
}
.duo {
  text-align: center;

  .btn {
    background: #e9f3e8;
    border-color: #e9f3e8;
    color: #2c6e14;
    box-shadow: none;
    width: 100% !important;
    display: block !important;
    i {
      color: #2c6e14;
    }
    &:hover {
      background: #c6dbc4 !important;
      border-color: #c6dbc4;
      color: #2c6e14 !important;
      box-shadow: none;
    }
  }
  .btn-link-basic {
    background: none;
    border: none;
    &:hover {
      background: none !important;
      text-decoration: underline;
    }
  }
}

.duo-push {
  display: none;
}

@media screen and (min-width: 992px) {
  .modal-start-btn {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 991px) {
  .MITCard {
    background-image: url(/MITIDCard.png);
    background-size: cover !important;
    background-repeat: no-repeat;
    text-align: left;
    display: flex;
    height: 222px !important;
    width: 350px !important;
    position: relative;
    left: 50%;
    margin-left: -175px !important;
    border-radius: 0.5rem !important;
    font-size: 1rem !important;
    .ID {
      margin-top: 191px !important;
    }
    .name {
      position: absolute;
      margin-top: 103px !important;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 15px;
      max-width: 175px !important;
    }
    .profile-avatar {
      margin-left: 181px !important;
      width: 107px !important;
      height: 115px !important;
      margin-top: 73px !important;
    }
  }

  .modal.containerless .modal-body {
    overflow: scroll !important;
  }
  .modal .modal-content {
    .d-flex {
      flex-direction: column;

      div {
        width: 100%;
        height: 400px;
      }
      div:nth-child(1) {
        background-position: 50% 0%;
        background-size: 155%;
      }
      div:nth-child(3) {
        background-position: 70% -10%;
        background-size: 150%;
      }
      div:nth-child(2) {
        background-position: 50% 0%;
        background-size: 150%;
      }
    }
    .modal-bottom {
      margin-bottom: 0rem;
      padding: 0rem;
    }
    .p-4 {
      padding: 1.5rem !important;
    }
  }
  .modal {
    width: 98% !important;
  }
  .duo-push {
    display: block;
  }
  .modal-wrapper .modal.containerless .modal-body {
    max-height: 98vh !important;
  }
  .footer-bar {
    color: #000;
    position: absolute;
    z-index: 5;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    padding: 2rem;
    background: #ebebeb;
  }
  .intro-text {
    margin: 0rem 3rem;
    color: #000;

    h1 {
      color: #ed1c42;
      margin-bottom: 1rem;
    }
    h2 {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
    h3 {
      font-weight: 100;
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }

  .form-container {
    min-height: 50vh;
    width: 350px;
    margin-top: 2rem; //desktop only
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .form-control {
      font-size: 1.25rem;
      text-align: center;
    }
  }

  .atlas-theme-intro .rightbar {
    border-radius: 1rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.cardbox {
  div {
    i {
      display: block;
      font-size: 2.5rem;
      text-align: center;
      width: 100%;
      margin: 0.75rem 0rem;
    }
    border-radius: 0.5rem;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem;
    padding-bottom: 1rem;
    font-weight: 600;
    text-align: center;
    background-color: aquamarine;
  }

  &:nth-child(1) {
    div {
      background: #accbf2;
      color: #001cbf;
    }
  }

  &:nth-child(2) {
    div {
      background: #e6cbff;
      color: #7300de;
    }
  }

  &:nth-child(3) {
    div {
      background: #dcf282;
      //  transform: rotate(-10deg);
      color: #109101;
    }
  }

  &:nth-child(4) {
    div {
      background: #ffe3e8;
      //  transform: rotate(-5deg);
      color: #d3002b;
    }
  }

  &:nth-child(5) {
    div {
      background: #bfe1ff;
      color: #0088ff;
    }
  }

  &:nth-child(6) {
    div {
      background: #a8fff3;
      // transform: rotate(10deg);
      color: #006356;
    }
  }
}

.profile-hover > div > span > a {
  right: 17rem;
  position: absolute;
}
.profile-bar {
  color: #000;
  position: fixed;
  display: flex;
  top: 1rem;
  right: 1rem;
  background-color: #e6e6f1;
  border-radius: 1rem;
  padding: 0.5rem;
  z-index: 500;
  .profile-avatar {
    width: 4.5rem;
    height: 4.5rem;
    flex-grow: 0 !important;
    margin-right: 0.5rem;
  }

  .pic {
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.1);
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    i {
      text-align: center;
      width: 100%;
      align-self: center;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.5rem;
    }
  }
  .name {
    font-weight: 600;
    font-size: 1rem;
    padding-top: 0.125rem;
    padding-right: 1rem;
    max-width: 240px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .mitid {
    font-size: 0.8rem;
    opacity: 0.7;
  }
  .link {
    margin-bottom: 0.125rem;
    font-size: 0.8rem;
  }
}

h3 {
  font-size: 1.2rem !important;
}
* {
  line-height: 1.5 !important;
}
@media (max-width: 991px) {
  .profile-bar {
    right: 0rem;
  }
}
@media (max-width: 991px) {
  .appName {
    width: auto !important;
    display: none;
  }
  .profile-hover > div > span > a {
    display: none;
  }
  .profile-bar {
    position: absolute !important;
    right: 2rem !important;
    .pic {
      display: none;
    }
  }
  .atlas-theme-intro .rightbar {
    min-height: 60vh;
    border-bottom-left-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
    height: auto !important;
    padding-bottom: 10rem;
  }
  .duo-logo {
    display: none;
  }

  .MITCard {
    display: none;
  }
  .profile-bar {
    position: relative !important;
    right: 1rem !important;
    top: 0rem !important;
  }
}
.ReactCrop {
  margin-bottom: -0.5rem;
}
.modal.auto-height .modal-dialog .modal-content {
  overflow: hidden;
}
#pictureCropper {
  .modal-body {
    padding: 0rem;
    text-align: center;
  }
  .modal-content {
    .modal-body {
      height: 95vh;
      max-height: 95vh;
    }
  }
  .modal-header {
    display: none;
  }
  .modal-footer {
    height: 0px;
    padding: 0rem;
    border: none;
    background-color: none;
    a {
      position: absolute;
      margin-top: -5rem;
      margin-left: -10rem;
      white-space: nowrap;
    }
  }
}

.profile-avatar {
  flex-grow: 0 !important;
}

.MITCard-container {
  text-align: center;
}
.MITCard {
  background-image: url('/MITIDCard.png');
  background-repeat: no-repeat;
  text-align: left;
  display: flex;
  height: 293px;
  width: 460px;
  position: relative;
  left: 50%;
  margin-left: -230px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.07), 0px 16px 16px rgba(0, 0, 0, 0.07), 0px 32px 32px rgba(0, 0, 0, 0.07);
  border-radius: 1rem;
  font-size: 1.25rem;

  .name {
    position: absolute;
    margin-top: 123px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 15px;
    max-width: 257px;
  }

  .leftside {
    flex-grow: 1;
  }
  .rightside {
    flex-grow: 1;
  }

  .ID {
    position: absolute;
    margin-top: 252px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 15px;
    color: #fff;
  }
  .profile-avatar {
    border-radius: 0rem;
    max-height: auto;
    max-width: auto;
    margin-left: 236px;
    width: 140px;
    height: 150px;
    margin-top: 95px;
    background-size: cover;
  }
}
.btn-primary {
  &:hover {
    background-color: #029600 !important;
    border-color: #029600 !important;
    box-shadow: 0px 2px 2px #02960010, 0px 4px 4px #02960010, 0px 8px 8px #02960010, 0px 16px 16px #02960010, 0px 32px 32px #02960010 !important;
    &:active {
      background-color: #029600 !important;
      border-color: #029600 !important;
      box-shadow: 0px 2px 2px #02960020, 0px 4px 4px #02960020, 0px 8px 8px #02960020, 0px 16px 16px #02960020 !important;
    }

    &:focus {
      background-color: #029600 !important;
      border-color: #029600 !important;
      box-shadow: 0px 2px 2px #02960020, 0px 4px 4px #02960020, 0px 8px 8px #02960020, 0px 16px 16px #02960020 !important;
    }
  }
}

.resend-container {
  margin: 2rem;
  margin-top: 3rem;
}

// animation for that box of expired.
.slide-in-elliptic-top-fwd {
  -webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-14 12:53:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.loader-page {
  background: rgb(255, 34, 73);
  background: linear-gradient(166deg, rgba(255, 34, 73, 1) 0%, rgba(110, 15, 36, 1) 100%);
}

.profile-hover.fixed-profile {
  z-index: 1;
}
